import React, { Component } from 'react';

class Navbar extends Component {
  render() {
    return (
      <nav className="grey lighten-3">
        <div className="container">
          <div className="row">
            <div className="col s12">
              <div className="nav-wrapper">
                <a href="/" className="brand-logo black-text">
                  <span className="hide-on-small-only">Relatively Unique</span>
                  <span className="show-on-small hide-on-med-and-up" style={{fontSize: "1.5rem"}}>Relatively Unique</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar;
