import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Privacy from './components/Privacy';

function App() {
  return (
    <div className="relativelyunique-app">
      <Navbar />
      <Routes>
        <Route path="/impressum" element={<About />} />
        <Route path="/datenschutz" element={<Privacy />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
